import ItemsSection from "../MainPage/ItemsSection";
import Item from "../MainPage/Item";

import classes from "../MainPage/AvailableProductTypes.module.css";

const DUMMY_BEDS = [
    {
        id: 'dormitoare/lettovienna',
        name: 'LETTO VIENA',
        description: 'descriere',
        img: require('../../assets/productTypes/dormitoare/letto_vienna.png')
    },
    {
        id: 'dormitoare/letto_kiev',
        name: 'LETTO KIEV',
        description: 'descriere',
        img: require('../../assets/productTypes/dormitoare/letto_kiev.png')
    },
    {
        id: 'dormitoare/letto_riga',
        name: 'LETTO RIGA',
        description: 'descriere',
        img: require('../../assets/productTypes/dormitoare/letto_riga.png')
    }
]

const Beds = () => {    
    const beds = DUMMY_BEDS.map((bed) => (
        <Item
            key={bed.id} 
            id={bed.id}
            name={bed.name}
            description={bed.description}
            img={bed.img}
        />));
    
    return (
        <section className={classes.product}>   
            <h1>Dormitoare</h1>         
            <ItemsSection>
                <ul>{beds}</ul>
            </ItemsSection>            
        </section>
    );    
};

export default Beds;