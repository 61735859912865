import ItemsSection from "../MainPage/ItemsSection";
import Item from "../MainPage/Item";

import classes from "../MainPage/AvailableProductTypes.module.css";

const DUMMY_SOFAS = [
    {
        id: 'canapele/sorento',
        name: 'SORENTO 2 LOCURI',
        description: 'descriere',
        img: require('../../assets/productTypes/canapele/sorento_2_posti.png')
    },
    {
        id: 'canapele/florida',
        name: 'FLORIDA 2 LOCURI',
        description: 'descriere',
        img: require('../../assets/productTypes/canapele/florida_2_posti.png')
    },
    {
        id: 'canapele/california',
        name: 'CALIFORNIA 2 LOCURI',
        description: 'descriere',
        img: require('../../assets/productTypes/canapele/california_2.png')
    },
    {
        id: 'canapele/arizona',
        name: 'ARIZONA',
        description: 'descriere',
        img: require('../../assets/productTypes/canapele/arizona.png')
    },
    {
        id: 'canapele/malaga',
        name: 'MALAGA 2 LOCURI',
        description: 'descriere',
        img: require('../../assets/productTypes/canapele/malaga2.png')
    }
]

const Sofas = () => {    
    const sofas = DUMMY_SOFAS.map((sofa) => (
        <Item
            key={sofa.id} 
            id={sofa.id}
            name={sofa.name}
            description={sofa.description}
            img={sofa.img}
        />));
    
    return (
        <section className={classes.product}>   
            <h1>Canapele</h1>         
            <ItemsSection>
                <ul>{sofas}</ul>
            </ItemsSection>            
        </section>
    );    
};

export default Sofas;