import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import classes from './MainNavigation.module.css';

const MainNavigation = () => {
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href='/tipuri-produse' activeClassName={classes.active}>Simex Plus</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href='/tipuri-produse' activeClassName={classes.active}> HOME </Nav.Link>
            <Nav.Link href='/canapele' activeClassName={classes.active}> CANAPELE </Nav.Link> 
            <Nav.Link href='/coltare' activeClassName={classes.active}> COLȚARE </Nav.Link>     
            <Nav.Link href='/dormitoare' activeClassName={classes.active}> DORMITOARE </Nav.Link>     
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainNavigation;