import classes from './Item.module.css';
import { NavLink } from 'react-router-dom';

const Item = (props) => {
 
  return (
    <NavLink className={classes.itemNavLink} to={{
      pathname: `/${props.id}`,
      userProps: {name: props.name}
    }} >
      <li className={`${classes.product} ${classes.itemNavLink}`}>        
        <div>
          <h3>{props.name}</h3>
          <div className={classes.description}>{props.description}</div>
        </div>
        <div>
          <img src={props.img}></img> 
        </div>                
      </li>
    </NavLink>    
  );
};

export default Item;
