import {Redirect, Switch, Route} from "react-router-dom";


import './App.css';
import Header from './Components/Layout/Header';
import Layout from "./Components/Layout/Layout";
import AvailableProductTypes from './Components/MainPage/AvailableProductTypes';
import CornerSofas from "./Components/ProduceTypes/CornerSofas";
import Sofas from "./Components/ProduceTypes/Sofas";
import Beds from "./Components/ProduceTypes/Beds";
import ItemDetail from "./Components/ItemDetail";
import NotFound from "./Components/Pages/NotFound";
import React from "react";

// const Tables = React.lazy(() => import('./Components/ProduceTypes/Tables')); // laod page only then visit this path

function App() {
  return (
    <div className="App">  
        <Layout/>
        <Header/>         
          <Switch>
            <Route path='/' exact>
              <Redirect to='/tipuri-produse'></Redirect>            
            </Route>
            <Route path='/tipuri-produse'>            
              <AvailableProductTypes/>
            </Route>

            <Route path='/canapele' exact>
              <Sofas/>
            </Route>
            <Route path='/canapele/:itemId'>
              <ItemDetail/>
            </Route>

            <Route path='/coltare' exact>
              <CornerSofas/>
            </Route>
            <Route path='/coltare/:itemId'>
              <ItemDetail/>
            </Route>

            <Route path='/dormitoare' exact>
              <Beds/>
            </Route>
            <Route path='/dormitoare/:itemId'>
              <ItemDetail/>
            </Route>

            {/* <Route path='/mese' exact>
              <Tables/>
            </Route>
            <Route path='/mese/:itemId'>
              <ItemDetail/>
            </Route>
            
            <Route path='/scaune'>n
              <Chairs/>
            </Route> */}
            
            
            <Route path='*'>
              <NotFound/>
            </Route>
          </Switch>                
      <script src="nav.js" type="text/javascript"/>
    </div>
  );
}

export default App;
