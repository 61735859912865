import { Fragment } from 'react';

import classes from './Header.module.css'
import mainImage from '../../assets/header.jpg';

const Header = () => {
    return(
        <Fragment>            
            {/* <HeaderMeniu/>             */}
            <div className={classes['main-image']}>
                <img src={mainImage} alt='Gama noastră de produse!' />
            </div>
        </Fragment>
        
    );
};

export default Header;