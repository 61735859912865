import React from "react";
import classes from './ItemsSection.module.css';

const ItemsSection = (props) => {
    return (
        <div className={classes.product}>{props.children}</div>
    ) 
}

export default ItemsSection;
