import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import './ItemsDetails.css';

const ItemDetail = () => {
    const matchPath = useRouteMatch();
    const [images, setImages] = useState([])
    import(`../assets/productTypes${matchPath.url}`).then(res => setImages(res.default));
    
    return (
      <div>
        {Object.keys(images).map((key, index) => (
          <div>
            <img key={index} src={images[key]} alt={key}/>
          </div>
        ))}
      </div>
    );
  };


export default ItemDetail;