
import ItemsSection from "../MainPage/ItemsSection";
import Item from "../MainPage/Item";

import classes from "../MainPage/AvailableProductTypes.module.css";

const DUMMY_SOFAS = [
    {
        id: 'coltare/sorento',
        name: 'SORRENTO',
        description: 'descriere',
        img: require('../../assets/productTypes/coltare/default.png')
    },
    {
        id: 'coltare/sorento_angulo',
        name: 'SORRENTO ANGULO',
        description: 'descriere',
        img: require('../../assets/productTypes/coltare/sorento_angulo.png')
    },
    {
        id: 'coltare/florida',
        name: 'FLORIDA',
        description: 'descriere',
        img: require('../../assets/productTypes/coltare/florida.png')
    },
    {
        id: 'coltare/florida_angulo',
        name: 'FLORIDA ANGULO',
        description: 'descriere',
        img: require('../../assets/productTypes/coltare/florida_angulo.png')
    },
    {
        id: 'coltare/alaska',
        name: 'ALASKA',
        description: 'descriere',
        img: require('../../assets/productTypes/coltare/alasca.png')
    },
    {
        id: 'coltare/california',
        name: 'CALIFORNIA',
        description: 'descriere',
        img: require('../../assets/productTypes/coltare/california.png')
    },
    {
        id: 'coltare/california_angulo',
        name: 'CALIFORNIA ANGOLO',
        description: 'CALIFORNIA ANGOLO',
        img: require('../../assets/productTypes/coltare/california_angulo.png')
    },
    {
        id: 'coltare/capri',
        name: 'CAPRI',
        description: '',
        img: require('../../assets/productTypes/coltare/capri.png')
    }
]

const CornerSofas = () => {    
    const sofas = DUMMY_SOFAS.map((sofa) => (
        <Item
            key={sofa.id} 
            id={sofa.id}
            name={sofa.name}
            description={sofa.description}
            img={sofa.img}
        />));
    
    return (
        <section className={classes.product}>   
            <h1>COLȚARE</h1>         
            <ItemsSection>
                <ul>{sofas}</ul>
            </ItemsSection>            
        </section>
    );    
};

export default CornerSofas;