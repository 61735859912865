import React from "react";
import classes from './AvailableProductTypes.module.css';
import ItemsSection from './ItemsSection';
import Item from "./Item";

const DUMMY_PRODUCES = [
    {
        id: 'canapele',
        name: 'Canapele',
        description: 'descriere',
        img: require('../../assets/productTypes/sofa.jpg')
    },
    {
        id: 'coltare',
        name: 'Colțare',
        description: 'descriere',
        img: require('../../assets/productTypes/cornerSofa.jpg')
    },
    {
        id: 'dormitoare',
        name: 'Dormitoare',
        description: 'descriere',
        img: require('../../assets/productTypes/dormitor.png')
    },
    // {
    //     id: 'P5',
    //     name: 'Fotolii',
    //     description: 'descriere',
    //     img: require('../../assets/productTypes/sofa.jpg')
    // },
    
    // {
    //   id: 'mese',
    //   name: 'Mese',
    //   description: 'descriere',
    //   img: require('../../assets/productTypes/sofa.jpg')
    // },
    // {
    //   id: 'scaune',
    //   name: 'Scaune',
    //   description: 'descriere',
    //   img: require('../../assets/productTypes/sofa.jpg')
    // }    
  ];

  const DUMMY_PROMO = [
    {
      id: 'P1',
      name: 'Mese',
      description: 'descriere',
      img: require('../../assets/productTypes/sofa.jpg')
    },
    {
      id: 'P2',
      name: 'Scaune',
      description: 'descriere',
      img: require('../../assets/productTypes/sofa.jpg')
    },
    {
        id: 'P3',
        name: 'Dormitoare',
        description: 'descriere',
        img: require('../../assets/productTypes/sofa.jpg')
    }
  ];

const AvailableProductTypes = () =>{    
    const productTypes = DUMMY_PRODUCES.map((productType) => (
        <Item
            key={productType.id}
            id={productType.id}
            name={productType.name}
            description={productType.description}
            img={productType.img}
        />));

    const promos = DUMMY_PROMO.map((promo) => (
        <Item
            key={promo.id} 
            id={promo.id}
            name={promo.name}
            description={promo.description}
            img={promo.img}
        />));
    
    return (
        <section className={classes.product}>   
            <h1>TIPURI PRODUSE</h1>         
            <ItemsSection>
                <ul>{productTypes}</ul>
            </ItemsSection>
            {/* <h1>PROMOȚII</h1>
            <ItemsSection>
                <ul>{promos}</ul>
            </ItemsSection> */}
        </section>
    );
};

export default AvailableProductTypes;